import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import HomeComponent from './components/mainpage/HomeComponent'
import ContactComponent from './components/mainpage/ContactComponent'
import AboutComponent from './components/mainpage/AboutComponent'
import BTLComponent from './components/pages/BTLComponent'
import BrandingComponent from './components/pages/BrandingComponent'
import MarketingComponent from './components/pages/MarketingComponent'
import ProduccionComponent from './components/pages/ProduccionComponent'
import TradeComponent from './components/pages/TradeComponent'
import { Route, Switch } from 'react-router-dom'
import ServicesComponent from './components/mainpage/ServicesComponent'
import ScrollToTop from './components/util/ScrollToTop'
import { ApolloClient,ApolloProvider, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
  uri: process.env.BACKENDURL,
  cache: new InMemoryCache()
});


function App() {
    return (
      <ApolloProvider client={client}>
        <Router>
          <div className="test-st font-custom">
          <ScrollToTop/>
          <Switch>
            <Route exact path="/">
              <>
                <HomeComponent/>
                <AboutComponent/>
                <ServicesComponent/>
                <ContactComponent/>
              </>
            </Route>
            <Route path="/BTLservices">
              <BTLComponent/>
            </Route>
            <Route path="/BrandingServices">
              <BrandingComponent/>
            </Route>
            <Route path="/MarketingServices">
              <MarketingComponent/>
            </Route>
            <Route path="/TradeServices">
              <TradeComponent/>
            </Route>
            <Route path="/ProductionServices">
              <ProduccionComponent/>
            </Route>
          </Switch>
        </div>
      </Router>
      </ApolloProvider>
    )
}


export default App